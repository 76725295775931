
let dateTimeFormat = (date)=>{
  if(!date || typeof date !== 'string')return;
  let dd = new Date(date);
  let year = dd.getFullYear().toString();
  let month = addZero(dd.getMonth()+1);
  let day = addZero(dd.getDate());
  let hh =  addZero(dd.getHours());
  let mm = addZero(dd.getMinutes());
  let ss = addZero(dd.getSeconds());
  return `${year}-${month}-${day} ${hh}:${mm}:${ss}`;

}
function addZero(num){
  return num <10 ? '0'+num : num.toString();
}

//金额转换为三位一撇
const changeToThree = (value) => {
  if(!value){return value}
  if (typeof value == "number") {
    value += ''
  }
  var data = value.split(".");
  let dataBol = true;
  let num = value;
  if(value<0){
    dataBol = false;
  }
  var data1 = data[0];
  var data2 = data[1];
  if (data2 != null && data2.length == 2) {
    if(dataBol){
      num = data1.replace(/[^\d.]+/g, '').replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,') + '.' + data2;
    }else{
      num = '-' + data1.replace(/[^\d.]+/g, '').replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,') + '.' + data2;
    }
    return num
  } else {
    if(dataBol){
      num = value.replace(/[^\d.]+/g, '').replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,');
    }else{
      num = '-' + value.replace(/[^\d.]+/g, '').replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,');
    }
    return num
  }
}


export {dateTimeFormat,changeToThree}