import axios from 'axios'
import instance from './axios'

// 用户退出登录
export const logout = (para)=>{
  return instance.post('/account/logout',para)
}
// 用户登录
export const accountLogin = (para)=>{
  return axios.post('/rest-admin/open/login',para)
}
// export const accountLogin = (para)=>{
//   return axios.post('/open/login',para)
// }
// 发送短信
export const sendSmsCode = (para)=>{
  return axios.post('/rest-admin/open/sendSmsCode',para)
}
// 获取cookie
export const getCookie = (name)=>{
  var arr,reg = new RegExp("(^|)"+name+"=([^;]*)(;|$)");
  if(arr = document.cookie.match(reg)){
    return unescape(arr[2])
  }else{
    return null;
  }
}
// 清除cookie
export const delCookie = (name,domain)=>{
  domain = domain || '';
  var exp = new Date();
  exp.setTime(exp.getTime()-10000);
  var cval = getCookie(name);
  if(cval != null){
    document.cookie = name + "=" +cval +";domain="+ domain + ";expires=" +exp.toGMTString();
  }
}

// 清除所有cookie
export const delAllCookie = () => {
  delCookie('_yhl_admin_id', 'localhost')
  delCookie('_yhl_admin_ticket', 'localhost')
  delCookie('_yhl_admin_id', '.360aidata.com')
  delCookie('_yhl_admin_ticket', '.360aidata.com')
}
