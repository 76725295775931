import store from '../../store';


let provinceObj = store.state.dictionary.provinceObj;
let cityObj = store.state.dictionary.cityObj;
let areaObj = store.state.dictionary.areaObj;

export const getProvinceName = (id)=>{
  return provinceObj[id]||'';
}
export const getCityName = (id)=>{
  return cityObj[id]||'';
}
export const getAreaName = (id)=>{
  return areaObj[id]||'';
}