import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import None from '../views/None.vue'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'Home',
  meta: {
    requiresAuth: true,
    title:'winback_admin'
  },
  component: Home,
  hidden: true,
  children: [
    {
    path: 'memberManagement',
    name: '会员管理',
    meta: {
      requiresAuth: true
    },
    component: None,
    hidden: true,
    children: [
      {
      path: 'user',
      name: '用户管理',
      meta: {
        requiresAuth: true
      },
      component: () =>
        import ('../views/member-manage/user-manage.vue'),
      hidden: true,
    }, 
    {
      path: 'unregister',
      name: '用户注销申请列表',
      meta: {
        requiresAuth: true
      },
      component: () =>
        import ('../views/member-manage/unregister.vue'),
      hidden: true,
    }, 
    // {
    //   path: 'blacklist',
    //   name: '黑名单管理',
    //   meta: {
    //     requiresAuth: true
    //   },
    //   component: () =>
    //     import ('../views/member-manage/blacklist-manage.vue'),
    //   hidden: true,
    // }
  ]
  }, {
    path: 'verifyManagement',
    name: '审核管理',
    meta: {
      requiresAuth: true
    },
    component: None,
    hidden: true,
    children: [
      {
        path: 'userVerify',
        name: '用户认证审核',
        meta: {
          requiresAuth: true
        },
        component: () =>
          import ('../views/verify-manage/user-verify.vue'),
        hidden: true,
      }, 
      // {
      //   path: 'companyVerify',
      //   name: '企业认证申请',
      //   meta: {
      //     requiresAuth: true
      //   },
      //   component: () =>
      //     import ('../views/verify-manage/company-verify.vue'),
      //   hidden: true,
      // }, 
      // {
      //   path: 'laywerVerify',
      //   name: '律师认证申请',
      //   meta: {
      //     requiresAuth: true
      //   },
      //   component: () =>
      //     import ('../views/verify-manage/laywer-verify.vue'),
      //   hidden: true,
      // }, 
      {
        path: 'biddingRelease',
        name: '招标发布审核',
        meta: {
          requiresAuth: true
        },
        component: () =>
          import ('../views/verify-manage/bidding-release.vue'),
        hidden: true,
      }, {
        path: 'seekHelp',
        name: '求助发布审核',
        meta: {
          requiresAuth: true
        },
        component: () =>
          import ('../views/verify-manage/seek-help.vue'),
        hidden: true,
      },

    ]
  }, {
    path: 'inviteSubmitManagement',
    name: '投招标管理',
    meta: {
      requiresAuth: true
    },
    component: None,
    hidden: true,
    children: [{
      path: 'bidding',
      name: '招标管理',
      meta: {
        requiresAuth: true
      },
      component: () =>
        import ('../views/invite-submit-manage/bidding.vue'),
      hidden: true,
    }, {
      path: 'seekhelp',
      name: '求助管理',
      meta: {
        requiresAuth: true
      },
      component: () =>
        import ('../views/invite-submit-manage/seekhelp.vue'),
      hidden: true,
    }, {
      path: 'signup',
      name: '投标报名管理',
      meta: {
        requiresAuth: true
      },
      component: () =>
        import ('../views/invite-submit-manage/signup.vue'),
      hidden: true,
    }, ]
  }, {
    path: 'orderManagement',
    name: '订单管理',
    meta: {
      requiresAuth: true
    },
    component: None,
    hidden: true,
    children: [{
      path: 'qualificationUnlock',
      name: '资格解锁订单',
      meta: {
        requiresAuth: true
      },
      component: () =>
        import ('../views/order-manage/qualification-unlock.vue'),
      hidden: true,
    }, {
      path: 'bidSecurity',
      name: '招标保证金订单',
      meta: {
        requiresAuth: true
      },
      component: () =>
        import ('../views/order-manage/bid-security.vue'),
      hidden: true,
    }, ]
  }, {
    path: 'systemManagement',
    name: '系统管理',
    meta: {
      requiresAuth: true
    },
    component: None,
    hidden: true,
    children: [{
      path: 'adminAccount',
      name: '后台账号管理',
      meta: {
        requiresAuth: true
      },
      component: () =>
        import ('../views/system-manage/admin-account.vue'),
      hidden: true,
    }, 
    // {
    //   path: 'adminPermission',
    //   name: '后台角色组管理',
    //   meta: {
    //     requiresAuth: true
    //   },
    //   component: () =>
    //     import ('../views/system-manage/admin-permission.vue'),
    //   hidden: true,
    // }, 
    // {
    //   path: 'keywordFilter',
    //   name: '关键字过滤管理',
    //   meta: {
    //     requiresAuth: true
    //   },
    //   component: () =>
    //     import ('../views/system-manage/keyword-filter.vue'),
    //   hidden: true,
    // }, 
    {
      path: 'bidType',
      name: '招标类型管理',
      meta: {
        requiresAuth: true
      },
      component: () =>
        import ('../views/system-manage/bid-type.vue'),
      hidden: true,
    }, 
    {
      path: 'systemParams',
      name: '系统参数管理',
      meta: {
        requiresAuth: true
      },
      component: () =>
        import ('../views/system-manage/system-params.vue'),
      hidden: true,
    },
    {
      path: 'adminNotice',
      name: '后台-公告管理',
      meta: {
        requiresAuth: true
      },
      component: () =>
        import ('../views/system-manage/admin-notice.vue'),
      hidden: true,
    },
    {
      path: 'adminBanner',
      name: 'banner管理',
      meta: {
        requiresAuth: true
      },
      component: () =>
        import ('../views/system-manage/admin-banner.vue'),
      hidden: true,
    },
    {
      path: 'documentManage',
      name: '文稿管理',
      meta: {
        requiresAuth: true
      },
      component: () =>
        import ('../views/system-manage/document-manage.vue'),
      hidden: true,
    },
    {
      path: 'documentEditor',
      name: '文稿编辑',
      meta: {
        requiresAuth: false
      },
      component: () =>
        import ('../views/system-manage/coms/doc-add.vue'),
      hidden: false,
    },
   ]
  }, {
    path: 'serviceCenter',
    name: '客服中心',
    meta: {
      requiresAuth: true
    },
    component: None,
    hidden: true,
    children: [{
      path: 'shortMessageRecord',
      name: '短信发送记录',
      meta: {
        requiresAuth: true
      },
      component: () =>
        import ('../views/service-center/short-message-record.vue'),
      hidden: true,
    }, 
    // {
    //   path: 'pushRecord',
    //   name: '推送发送记录',
    //   meta: {
    //     requiresAuth: true
    //   },
    //   component: () =>
    //     import ('../views/service-center/push-record.vue'),
    //   hidden: true,
    // }, 
    {
      path: 'mailRecord',
      name: '站内信发送记录',
      meta: {
        requiresAuth: true
      },
      component: () =>
        import ('../views/service-center/mail-record.vue'),
      hidden: true,
    }, {
      path: 'userChatRecord',
      name: '用户聊天记录',
      meta: {
        requiresAuth: true
      },
      component: () =>
        import ('../views/service-center/user-chat-record.vue'),
      hidden: true,
    }, {
      path: 'customerServiceWork',
      name: '客服工单管理',
      meta: {
        requiresAuth: true
      },
      component: () =>
        import ('../views/service-center/customer-service-work.vue'),
      hidden: true,
    }, ]
  }]
}, 
{
  path: '/login',
  name: 'Login',
  meta:{
    title:'winback'
  },
  component: () =>
    import ('../views/Login.vue')
},
{
  path: '/docpage',
  name:'Docpage',
  component: () => import('../views/docpage/docpage.vue') 
},
{
  path: '/sharepage',
  name:'SharePage',
  meta:{
    title:'打开赢回来'
  },
  component: () => import('../views/share-page/sharePage.vue') 
}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router