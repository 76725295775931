<!--  -->
<template>
  <div class="slides">
    <div class="logo">
      赢回来
    </div>
    <div class="menus">
      <el-menu
        router
        :default-active="$route.path"
        class="el-menu-vertical-demo"
        background-color="#304156"
      text-color="#bfcbd9"
      active-text-color="#409eff"
      @select="selectHandle">
        <el-submenu :index="items.path" v-for="(items,ind) in slide" :key="ind">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span style="font-size:15px;">{{items.name}}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item :index="'/'+items.path+'/'+item.path" v-for="(item,ind2) in items.children" :key="ind2" :style='{"color":$route.name==item.name?"#409EFF":"rgb(191, 203, 217)"}' v-show="item.hidden">{{item.name}}</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      slide:[],
    };
  },
  components: {},
  computed: {},
  methods: {
    selectHandle(index,indexPath){
      for(let i=0;i<this.slide.length;i++){
        if(indexPath[0] === this.slide[i].path){
          this.$emit('parPathname',this.slide[i].name);
        }
      }
    },
    getSlide(){
      this.slide = [];
      let routes=this.$router.options.routes;
      
      let pageRoutes = [];
      for (let i = 0; i < routes.length; i++) {
          if (routes[i].path === '/') {
            pageRoutes = routes[i].children;
          }
      }
      // console.log(pageRoutes,topLight)
      let slide =[];
      for (let i = 0; i < pageRoutes.length; i++) {
          slide.push(pageRoutes[i])
      }
      this.slide = slide;

    }
  },
  mounted(){
    this.getSlide()
  }
}

</script>
<style>
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }
  .el-menu-item-group__title{
    padding:0!important;
  }
</style>

<style lang='scss' scoped>
.slides{
  .logo{
    line-height: 61px;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 700;
    color: #fff;
    background: #304156;

  }
  .el-menu-item{
    font-size: 15px;
  }
}
</style>