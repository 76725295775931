
import {allArea} from '../../api/open'
export default{
  state:{
    registerSourceOptions:{
      "1":"安卓",
      "2":"IOS",
      "3":"后台"
    },
    statusOptions:{
      '0':'正常',
      '1':'禁言',
      '2':'禁止登录',
      '3':'注销'
    },
    targetStatus:{//标的状态 招标状态
      // '-1':'待支付',
      // '0':'支付中',
      // '1':'支付失败',
      '2':'审核中',
      '3':'审核失败',
      '4':'招标中',
      '5':'选标中',
      '6':'已结标',
      '7':'流标',
      '8':'终止',
      '9':'废标'
    },
    showAuthority:{//标的权限
      1:'全部可见',
      2:'仅白名单',
      3:'仅白名单和灰名单'
    },
    winMode:{//中标方式
      1:'公开',
      2:'邀请',
      3:'摇号'
    },
    identityType:{//认证类型
      'user':'个人',
      'lawyer':'律师',
      'company':'企业',
      'expert_party':'专家团'
    },
    applyStatus:{//认证审核状态
      '0':'审核中',
      '1':'审核通过',
      '2':'审核拒绝'
    },
    identity:{//身份
      1:'原告',
      2:'被告',
      3:'代理人'
    },
    payStatus:{    // 支付状态
      1:'支付失败',
      2:'支付成功',
      4:'退款失败',
      5:'退款成功',
      "color_1":"orange",
      "color_2":"green"
    },
    fixedJumpTypeOptions:{//跳转类型
      '0':'无跳转',
      '1':'超链接和h5',
      '2':'标的详情',
      '3':'招标大厅',
      '4':'发布招标',
      '5':'角色认证'
    },
    provinceObj:{},
    cityObj:{},
    areaObj:{}
  },
  mutations:{
    GET_ALL_PROVINCE(state,data){
      state.provinceObj = data;
    },
    GET_ALL_CITY(state,data){
      state.cityObj = data;
    },
    GET_ALL_AREA(state,data){
      state.areaObj = data;
    }
  },
  actions:{
    getAllArea({commit}){
      allArea().then(res=>{
        let data = res.data;
        if(data.code === '000'){
          let list = data.content;
          let province = {};
          let city = {};
          let area = {};
          let cityList=[];
          let areaList=[];
          for(let i=0;i<list.length;i++){
            province[list[i].provinceId] = list[i].provinceName;
            cityList = cityList.concat(list[i].cityList);
          }
          for(let i=0;i<cityList.length;i++){
            city[cityList[i].cityId] = cityList[i].cityName;
            areaList = areaList.concat(cityList[i].areaList)
          }
          for(let i=0;i<areaList.length;i++){
            area[areaList[i].areaId] = areaList[i].areaName;
          }
          commit('GET_ALL_PROVINCE',province);
          commit('GET_ALL_CITY',city);
          commit('GET_ALL_AREA',area);
        }else{
          console.log(data.desc)
        }
      })
    }
  }
}