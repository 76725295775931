import axios from 'axios'
 

// 所有省市区
export const allArea = (para)=>{
  return axios.get('/rest-admin/open/assistant/wholeArea',{params:para})
}
// 获取省 
export const getProvince = (para)=>{
  return axios.get('/rest-admin/open/assistant/provinceList',{params:para})
}
// 获取市
export const getCity = (para)=>{
  return axios.get('/rest-admin/open/assistant/cityList',{params:para})
}
// 获取区
export const getArea = (para)=>{
  return axios.get('/rest-admin/open/assistant/areaList',{params:para})
}
// 初始化短信content参数
export const initSmsContent = (para)=>{
  return axios.get('/rest-admin/open/sms/initSmsContent',{params:para})
}
// 获取wx权限验证配置
export const getWxConfig = (para) => {
  return axios.get('/rest-admin/open/assistant/initWXJSSDKConfigInfo',{params:para})
}
