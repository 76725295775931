import axios from 'axios'
import router from '../router/index.js'


let baseUrl = '/rest-admin/confined';
let instance = axios.create({
  baseURL:baseUrl,
  timeout:20000
})

// http response 拦截器
instance.interceptors.response.use(response=>{
  return response;
},error=>{
  if(error.response.status == 401){
    router.push({
      path:'/login'
    })
  }
  return Promise.reject(error);
})

export default instance;