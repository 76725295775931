<template>
  <div class="home">
    <el-container style="height:100vh;">
      <el-aside style='background: #304156;width: 200px;'>
        <slides ref='slide' @parPathname="parPathname"></slides>
      </el-aside>
      <el-main>
        <el-container>
          <el-header class="flex-row top-header">
            <el-breadcrumb separator="/">
              <el-breadcrumb-item :to="{ path: '/' }">赢回来</el-breadcrumb-item>
              <el-breadcrumb-item>{{submenuTitle}}</el-breadcrumb-item>
              <el-breadcrumb-item>{{curRoute.name}}</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="right flex-row">
              <div class="help">
                <i class="el-icon-question"></i>
                <span>帮助</span>
              </div>
              <div class="user">  
                <el-dropdown @command="handleCommand" trigger="click">
                  <span class="el-dropdown-link">
                    <img src="https://cdn-images-test.360pai.com/FlCKGRT6ZT3cGHLyAzXOdBTdNOXl">
                    {{user.name}}
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <!-- <el-dropdown-item command="editcode">修改密码</el-dropdown-item> -->
                    <el-dropdown-item command="loginout">退出登录</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </el-header>
          <el-main style="padding:15px;">
            <div style="background:#fff;min-height:300px;border-radius:5px;padding:15px;">
              <router-view></router-view>
            </div>
            
          </el-main>
        </el-container>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import slides from '@/components/slides.vue'
import {logout,delAllCookie} from '../api/login.js'
import {mapState} from 'vuex'

export default {
  name: 'Home',
  data(){
    return{
      curRoute:{},
      submenuTitle:'用户管理'
    }
  },
  watch:{
    "$route":function(to,from){
      this.curRoute = to;
    }
  },
  components: {
    slides
  },
  computed:{
    ...mapState({
      user:state => state.account.user
    })
  },
  methods:{
    handleCommand(command) {
      console.log(command)
      if(command === 'loginout'){
        this.loginOut();
      }
      if(command === 'editcode'){
        this.editCode();
      }
    },
    parPathname(val){
      this.submenuTitle = val;
    },
    loginOut(){
      logout().then(res=>{
        let data = res.data;
        if(data.code === '000'){
          delAllCookie();
          this.$message.success(data.desc);
          this.$router.push('/login');
        }else{
          this.$message.warning(data.desc);
        }
      })
    },
    editCode(){

    }
  },
  created(){
    // console.log(this.$router.options)
    // console.log(this.$route)
    this.curRoute = this.$route;
  }
}
</script>
<style scoped lang="scss">
  .el-aside {
    background-color: #D3DCE6;
    color: #333;
  }
  
  .el-main {
    background-color: #f8f8f8;
    color: #333;
    padding:0;
  }
  .top-header{
    background: #fff;
    height: 60px;
    justify-content:space-between;
    .el-breadcrumb{
      line-height: 60px;
    }
    .right{
      margin-right: 16px;
      .help{
        i.el-icon-question{
          font-size: 18px;
          color:#ddd;
          margin-right: 6px;
          vertical-align: middle;
        }
        margin-right: 16px;
        cursor: pointer;
      }
      .user{
        cursor: pointer;
        span{
          vertical-align: middle;
        }
        img{
          width: 24px;
          height: 24px;
          border-radius: 50%;
          border:1px solid #eee;
          vertical-align: middle;
          margin-right: 6px;
        }
      }
    }
    
  }
</style>
