import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import * as custom from './filters/filters.js'
import {getProvinceName,getCityName,getAreaName} from './assets/js/tools'

import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/reset.css'
import './assets/css/index.css'



Vue.use(ElementUI);

Object.keys(custom).forEach( key=> {
  Vue.filter(key,custom[key])
})

Vue.prototype.$getProvinceName = getProvinceName;
Vue.prototype.$getCityName = getCityName;
Vue.prototype.$getAreaName = getAreaName;

Vue.config.productionTip = false

Vue.prototype.COLOR = {
  "blue":"#409EFF",
  "green":"#67C23A",
  "red":"#F56C6C",
  "gray":"#909399",
  "orange":"#E6A23C"
}

router.beforeEach((to,from,next)=>{
  if(to.meta.title){
    document.title = to.meta.title;
  }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
