import Vue from 'vue'
import Vuex from 'vuex'
import account from './modules/account'
import dictionary from './modules/dictionary'

Vue.use(Vuex)
import createPersistedState from "vuex-persistedstate"
export default new Vuex.Store({
  modules: {
    account,
    dictionary
  },
  plugins:[
    createPersistedState({storage:window.sessionStorage})
  ]
})
