export default{
  state:{
    user:{name:''}
  },
  mutations:{
    GET_ACCOUNT_PROFILE(state, user) {
      state.user = user
    }
  },
  actions:{
    // getAccountProfile({commit}){

    // }
  }
}